import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import {
  CWExtractView,
  CWRefineView,
  CWRefineIllustView,
  CWLabelingView,
  CWLabelingIllustView,
} from '../model'

/**
 * clover framework NAV API
 */
export class CWMgntViewApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 추출 작업 내용 조회
   * @param params
   * @returns
   */
  extractView = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    extractList: CWExtractView[]
  }> => {
    const url = '/apis/work/view/extract'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 추출 검수 작업 내용 조회
   * @param params
   * @returns
   */
  extractVerifyView = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    extractVerifyList: CWExtractView[]
  }> => {
    const url = '/apis/work/view/extract/verify'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 정제 작업 내용 조회
   * @param params
   * @returns
   */
  refineView = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    refineList: CWRefineView[]
  }> => {
    const url = '/apis/work/view/refine'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 정제 검수 작업 내용 조회
   * @param params
   * @returns
   */
  refineVerifyView = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    refineVerifyList: CWRefineView[]
  }> => {
    const url = '/apis/work/view/refine/verify'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 정제 9-2 작업 내용 조회
   * @param params
   * @returns
   */
  refineIllustView = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    illustRefineList: CWRefineIllustView[]
  }> => {
    const url = '/apis/work/view/refine/illust'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 정제 9-2  검수 작업 내용 조회
   * @param params
   * @returns
   */
  refineIllustVerifyView = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    illustRefineVerifyList: CWRefineIllustView[]
  }> => {
    const url = '/apis/work/view/refine/illust/verify'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 라벨링 작업 내용 조회
   * @param params
   * @returns
   */
  labelingView = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    labelingList: CWLabelingView[]
  }> => {
    const url = '/apis/work/view/labeling'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 라벨링 검수 작업 내용 조회
   * @param params
   * @returns
   */
  labelingVerifyView = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    labelingList: CWLabelingView[]
  }> => {
    const url = '/apis/work/view/labeling/verify'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 라벨링 9-2 작업 내용 조회
   * @param params
   * @returns
   */
  labelingIllustView = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    illustLabelingList: CWLabelingIllustView[]
  }> => {
    const url = '/apis/work/view/labeling/illust'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 라벨링 9-2 검수 작업 내용 조회
   * @param params
   * @returns
   */
  labelingIllustVerifyView = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    illustLabelingVerifyList: CWLabelingIllustView[]
  }> => {
    const url = '/apis/work/view/labeling/illust/verify'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 통합검수 작업 내용 조회
   * @param params
   * @returns
   */
  IntergrationView = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    integList: CWLabelingView[]
  }> => {
    const url = '/apis/work/view/integration'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 통합검수9-2 작업 내용 조회
   * @param params
   * @returns
   */
  IntergrationIllustView = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    illustIntegList: CWLabelingIllustView[]
  }> => {
    const url = '/apis/work/view/integration/illust'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
