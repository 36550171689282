import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import { ColmMppnDefn, ColmMppnDefnTblNmAll } from '../model'

/**
 * 컬럼 정의서 API
 */
export class ColmMppnDefnApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 컬럼매퍼 테이블 검색
   */
  colmMppnDefnSearch = (
    params: {
      tobeTblPhyNm?: string
    } & BaseRequest,
  ): Promise<{ colmMppnDefnList: ColmMppnDefn[] }> => {
    const url = '/apis/colmMppnDefn/search'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 컬럼매퍼 테이블 리스트(중복제거됨)
   */
  colmMppnDefnTableList = (
    params: BaseRequest,
  ): Promise<{ colmMppnDefnTblNmAll: ColmMppnDefnTblNmAll[] }> => {
    const url = '/apis/colmMppnDefn/tblNmAll'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
