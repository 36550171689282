import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import { CLErrorMeta } from '../model'

/**
 * clover framework 기타 API
 */
export class CLEtcApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  errorMetas = (
    params: BaseRequest,
  ): Promise<{
    errorMetaList: CLErrorMeta[]
  }> => {
    const url = '/p/cloverframework/error-meta/list'
    return this.withData.post(url, ...splitParams(params))
  }
}
