import { Box, SxProps, TextFieldProps, Theme, Typography, alpha } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { flatSx } from './../lib/sx-props'
import { CLStyledTextField } from './CLStyledTextField'

const rootSx: SxProps<Theme> = {
  flex: 1,
  display: 'flex',
  alignItems: 'stretch',
  my: 0.2,
  '& .CLDocLabelInput-titleBox': {
    justifyContent: 'center',
    px: 0,
    py: 0.5,
    minWidth: 150,
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.15),
    // backgroundColor: 'primary.main',
    pl: 1,
    display: 'flex',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },

  '& .CLDocLabelInput-inputBox': {
    ml: 0.5,
    mr: 0,
    py: 0,
    flex: 1,

    '& .MuiInputBase-multiline': {
      p: 0,
      '& .MuiInputBase-input': {
        p: 0.8,
      },
    },
  },
}

type Props = {
  title: string
  readOnly?: boolean
  required?: boolean
} & TextFieldProps

export const CLDocLabelInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { title, sx, className, readOnly, fullWidth = true, required, ...restProps } = props

  return (
    <Box sx={flatSx(rootSx, sx)} className={clsx('CLDocLabelInput-root', className)}>
      <Box className="CLDocLabelInput-titleBox">
        <Typography fontSize="0.75rem">{title}</Typography>
        {required && <Typography ml={1}>*</Typography>}
      </Box>
      <Box className="CLDocLabelInput-inputBox">
        <CLStyledTextField
          {...restProps}
          ref={ref}
          fullWidth={fullWidth}
          readOnly={readOnly}
          sx={
            readOnly
              ? {
                  '& .MuiInputBase-root': {
                    backgroundColor: '#f5f5f5',
                    '& .MuiInputBase-input': {
                      WebkitTextFillColor: '#666',
                    },
                  },
                }
              : {}
          }
        />
      </Box>
    </Box>
  )
})

CLDocLabelInput.displayName = 'CLDocLabelInput'
