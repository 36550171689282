import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import {
  CWIntegrationIllustrionVo,
  CWIntegrationQuestion,
  CWLabelQA,
  CWLabelTag,
  CWLabelVarifyParagraph,
  CWLabelVerifyStory,
} from '../model'

export class CWIntegrationApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  qaInfo = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    integrationParagraphVo: CWLabelVarifyParagraph
    resultIntegrationQuestionVoList: CWIntegrationQuestion[]
    cwLabelTagList: CWLabelTag[]
  }> => {
    const url = '/p/crowd/integration_qa/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 등록
   */
  qaCreate = (
    params: {
      qaLabelingVerStepDataId: number
      cwLabelQAList: CWLabelQA[]
      statusCd: string
      assignId: number
      completePercent: number
    } & BaseRequest,
  ): Promise<{
    result: number
  }> => {
    const url = '/p/crowd/integration_qa/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  storyInfo = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    labelStoryVerifyVo: CWLabelVerifyStory
    cwLabelTagList: CWLabelTag[]
  }> => {
    const url = '/p/crowd/integration_story/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  storyCreate = (
    params: {
      storyLabelVerStepDataId: number
      story?: string
      mainSentence?: string
      statusCd: string
      chgrTf: boolean
      assignId: number
      completePercent: number
      primitiveBookId: number
      paragraphNumber: number
    } & BaseRequest,
  ): Promise<{
    saveResult: number
  }> => {
    const url = '/p/crowd/integration_story/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  illuInfo = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    integrationIllustrationVo: CWIntegrationIllustrionVo
    cwLabelTagList: CWLabelTag[]
  }> => {
    const url = '/p/crowd/integration_illu/verify/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  illuCreate = (
    params: {
      illLabelingVerStepDataId: number
      illustrationLabeling?: string
      utteranceTypeCd?: string
      statusCd: string
      chgrTf: boolean
      assignId: number
      completePercent: number
      paragraphNumber: number
      primitiveBookId: number
    } & BaseRequest,
  ): Promise<{
    saveResult: number
  }> => {
    const url = '/p/crowd/integration-illu/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 태깅값 수정
   */
  updateTag = (
    params: {
      workNumber: string
      id: number
      beforeTag: string
      tagTypeCd: string
      tag: string
    } & BaseRequest,
  ): Promise<{
    content: string
  }> => {
    const url = '/p/crowd/integration/tag-update'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
