import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import {
  CWTask,
  CWTaskManage,
  CWTaskManageDetail91,
  CWTaskManageDetail93,
  CWTask92,
  CWTaskManage92,
  CWTaskManageDetail92,
  CWTaskManageUserList,
  CWTaskManageIntegration,
  CWtaskManageIntegrationDetailVo,
  PagerData,
} from '../model'

export class CWTaskManageApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 신규 테스크 생성
   */
  create = (
    params: CWTask & BaseRequest,
  ): Promise<{
    taskId: number
  }> => {
    const url = '/p/crowdwork/task'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * Task 관리 목록 조회
   */
  list = (
    params: {
      rowsPerPage: number
      pageNumber: number
      taskManageSearchType: string
      boolAll?: boolean
      boolwork91?: boolean
      boolwork92?: boolean
      boolwork93?: boolean
      taskTitle?: string
    } & BaseRequest,
  ): Promise<{
    cwtaskManageVoList: PagerData<CWTaskManage>
  }> => {
    const url = '/p/crowdwork/taskmanage/list'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 워커 배정하기
   */
  assign = (
    params: {
      taskId: number
      taskStepCd: string
      workNumber: string
      userIds: string
      assignWorkerLimit: number
      assignBookLimit: number
      parentTaskId?: number
    } & BaseRequest,
  ): Promise<{
    assignTf: boolean
  }> => {
    const url = '/p/crowdwork/taskmanage/worker-assign'
    return this.withData.postJson(url, ...splitParams(params))
  }
  //
  //
  //
  /**
   * 워커 추가하기
   */
  assign02 = (
    params: {
      taskId: number
      taskStepCd: string
      workNumber: string
      userIds: string
      assignWorkerLimit: number
      assignBookLimit: number
      parentTaskId?: number
    } & BaseRequest,
  ): Promise<{
    updateTf: boolean
  }> => {
    const url = '/p/crowdwork/taskmanage/worker-update'
    return this.withData.postJson(url, ...splitParams(params))
  }
  //
  //
  //
  /**
   * Task 관리 목록 조회
   */
  detail93 = (
    params: {
      taskId: string
    } & BaseRequest,
  ): Promise<{
    cwtaskManageDetail93VoList: CWTaskManageDetail93[]
  }> => {
    const url = '/p/crowdwork/taskmanage/listDetail93'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * Task 관리 목록 조회
   */
  detail91 = (
    params: {
      taskId: string
    } & BaseRequest,
  ): Promise<{
    cwtaskManageDetail91VoList: CWTaskManageDetail91[]
  }> => {
    const url = '/p/crowdwork/taskmanage/listDetail91'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * Task 9-2 생성가능 task list
   */
  list92 = (
    params: BaseRequest,
  ): Promise<{
    taskList: CWTask92[]
  }> => {
    const url = '/p/crowdwork/taskmanage/list92'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * Task 9-2 관리 목록조회
   */
  taskList92 = (
    params: {
      taskManageSearchType?: string
      boolAll?: boolean
      boolwork91?: boolean
      boolwork92?: boolean
      boolwork93?: boolean
      taskTitle: string
    } & BaseRequest,
  ): Promise<{ cwtaskManage92VoList: CWTaskManage92[] }> => {
    const url = '/p/crowdwork/taskmanage/taskmanagelist92'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * Task 관리 목록 조회
   */
  detail92 = (
    params: {
      taskId: string
    } & BaseRequest,
  ): Promise<{
    cwtaskManageDetail92VoList: CWTaskManageDetail92[]
  }> => {
    const url = '/p/crowdwork/taskmanage/listDetail92'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * Task 관리 워커수 조회
   */
  userList = (
    params: {
      taskId: number
      taskStepCd: string
    } & BaseRequest,
  ): Promise<{
    taskStepCdCurrentList: CWTaskManageUserList[]
  }> => {
    const url = '/p/crowdwork/taskmanage/worker/listDetail'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * Task 통합검수
   */
  taskIntegList = (
    params: {
      workNumber?: string
    } & BaseRequest,
  ): Promise<{ cwtaskManageIntegrationVoList: CWTaskManageIntegration[] }> => {
    const url = '/p/crowdwork/taskmanage/integrationList'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * Task 통합검수 상세
   */
  taskIntegInfo = (
    params: {
      taskId?: number
    } & BaseRequest,
  ): Promise<{ cwtaskManageIntegrationDetailVo: CWtaskManageIntegrationDetailVo[] }> => {
    const url = '/p/crowdwork/taskmanage/integrationDetail'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * Task 통합검수 검색
   */
  showAfterFilter = (
    params: {
      workNumber?: string
      taskTitle?: string
    } & BaseRequest,
  ): Promise<any> => {
    const url = '/p/crowdwork/taskmanage/integrationList'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
