import LoginProfileLoader from '@/auth/LoginProfileLoader'
import CustomThemeWrapper from '@/components/CustomThemeWrapper'
import { SITE_META } from '@/constants'
import createEmotionCache from '@/createEmotionCache'
import { LightboxImageViewEventHandler } from '@/custom-events'
import StoreProvider from '@/store/StoreProvider'
import theme from '@/theme'
import { notoSansKR } from '@/theme/fonts'
import { CacheProvider, EmotionCache } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import * as timeago from 'timeago.js'
import ko from 'timeago.js/lib/lang/ko'

timeago.register('ko', ko)

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>ADMS</title>
        <link rel="icon" href="/images/clover-crowd.png" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <meta name="description" content={SITE_META.description} />
        <meta name="title" content={SITE_META.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={SITE_META.title} />
        <meta property="og:description" content={SITE_META.description} />
        <meta property="og:image" content={SITE_META.image} />
        <meta property="og:url" content={SITE_META.url} />
        <style jsx global>{`
          html {
            font-family: ${notoSansKR.style.fontFamily};
          }
        `}</style>
      </Head>
      <StoreProvider rootStoreInitialState={undefined}>
        {/* <ApiProvider> */}
        <ThemeProvider theme={theme}>
          <CustomThemeWrapper>
            <CssBaseline />
            <Component {...pageProps} />

            <LightboxImageViewEventHandler />
            <LoginProfileLoader />
            <ToastContainer
              position="top-center"
              hideProgressBar
              autoClose={2000}
              pauseOnHover
              draggable
            />
          </CustomThemeWrapper>
        </ThemeProvider>
        {/* </ApiProvider> */}
      </StoreProvider>
    </CacheProvider>
  )
}
