import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import {
  CWExpectWorkCostPopup,
  CWExpectWorkerCost,
  CWPaymentManage,
  CWSelectSettlementPayExceptVo,
  CWSettlementMonthRegHst,
  CWWorkCostPayment,
  CWWorkCostPaymentByWorkerInfo,
  CWWorkCostPaymentInfo,
  CWWorkCostPaymentWorkerInfo,
  CWWorkStepStopWorker,
  SettlementOpenManagement,
  SettlementPayExceptList,
} from '../model'

/**
 * 정산 관리 API
 */
export class CWPaymentManageApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 공정별 단가 조회 목록
   */
  unitPriceList = (
    params: {
      workMonth: string
      workNumber?: string
    } & BaseRequest,
  ): Promise<{ unitPriceList: CWPaymentManage[] }> => {
    const url = '/apis/unit-price/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 공정별 단가 수정
   */
  unitPricePaymentUpdate = (
    params: {
      updateWorkSettlements: CWPaymentManage[]
      insertWorkSettlementHsts: CWPaymentManage[]
    } & BaseRequest,
  ): Promise<{
    updateWorkSettlement: CWPaymentManage[]
    insertWorkSettlementHsts: CWPaymentManage[]
  }> => {
    const url = '/apis/unit-price-settlement/update'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 예상 워커별 작업비 조회목록
   */
  workerCostList = (
    params: {
      job?: string
      userGd?: number
      lgonId?: string
      userNm?: string
    } & BaseRequest,
  ): Promise<{ workerCostVoList: CWExpectWorkerCost[] }> => {
    const url = '/apis/expect-work-cost/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 예상 작업비 300 조회목록
   */
  workCost300List = (
    params: {
      fromStopDttm: string
      toStopDttm: string
      userGd?: number
    } & BaseRequest,
  ): Promise<{ workerCostVoList: CWWorkStepStopWorker[] }> => {
    const url = '/apis/300-work-cost/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 작업비 생성이력 조회
   */
  listRegSettlemenHist = (
    params: BaseRequest,
  ): Promise<{ listSettlementMonthHist: CWSettlementMonthRegHst[] }> => {
    const url = '/apis/settlement/reg-settlement-hist/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 작업비 생성
   */
  regSettlementMonth = (
    params: {
      yyyymm: string
    } & BaseRequest,
  ): Promise<{ listSettlementMonthHist: CWSettlementMonthRegHst[] }> => {
    const url = '/apis/settlement/reg-settlement/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 작업비 지급내역 과제별/공정별 목록
   */
  workCostSettlementHstList = (
    params: {
      searchYM: string
      job?: string
      workNumber?: string
      workStepCd?: string
    } & BaseRequest,
  ): Promise<{ workCostSettlementHstVoList: CWWorkCostPayment[] }> => {
    const url = '/apis/work-cost-settlement/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 작업비 지급내역 과제별/공정별 상세
   */
  workCostPaymentHstInfo = (
    params: {
      searchYM: string
      workNumber: string
      workStepCd: string
    } & BaseRequest,
  ): Promise<{ workCostSettlementHstInfoList: CWWorkCostPaymentInfo[] }> => {
    const url = '/apis/work-cost-settlement/info'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 작업비 지급내역 워커별 목록
   */
  workCostSettlementHstByWorkerList = (
    params: {
      yyyyMm: string
      job?: string
      userGd?: number
      userNm?: string
      workNumber?: string
      workStepCd?: string
      lgonId?: string
    } & BaseRequest,
  ): Promise<{ workCostSettlementHstByWorkerList: CWWorkCostPaymentWorkerInfo[] }> => {
    const url = '/apis/work-cost-settlement-worker/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 작업비 지급내역 워커별 상세
   */
  workCostSettlementHstByWorkerInfo = (
    params: {
      yyyyMm: string
      userId: number
    } & BaseRequest,
  ): Promise<{ workCostSettlementHstByWorkerInfoList: CWWorkCostPaymentByWorkerInfo[] }> => {
    const url = '/api/work-cost-settlement-worker/info'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 메인화면 예상 작업비 팝업
   */
  expectWorkCostPopupList = (
    params: {
      userId: number
    } & BaseRequest,
  ): Promise<{ workCostPopupList: CWExpectWorkCostPopup[] }> => {
    const url = '/api/expect-work-cost-popup/list'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 지급제외금액 조회
   */
  settlementPayExceptList = (
    params: {
      yyyyMm: string
      workLgonId?: string
    } & BaseRequest,
  ): Promise<{ cwSettlementPayExceptList: SettlementPayExceptList[] }> => {
    const url = '/apis/settlement-pay-except/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 워커정산 오픈여부 조회
   */
  findSettlementOpenManagement = (
    params: BaseRequest,
  ): Promise<{ findSettlementOpenManagement: SettlementOpenManagement[] }> => {
    const url = '/apis/settlement-open-management/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 지급제외 아이디로 조회
   */
  selectSettlementPayExceptList = (
    params: { yyyyMm: string; workLgonId?: string } & BaseRequest,
  ): Promise<{ selectSettlementPayExceptVoList: CWSelectSettlementPayExceptVo }> => {
    const url = '/apis/settlement-open-management-lgonid/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 지급제외 아이디로 등록
   */
  createSettlementPayExceptList = (
    params: {
      yyyyMm: string
      workUserId: number
      exceptAmout: number
      workNumber: string
      workStepCd: string
      exceptDesc?: string
    } & BaseRequest,
  ): Promise<{ insertSettlementPayExcept: number }> => {
    const url = '/apis/settlement-pay-except/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 워커정산 오픈여부 수정
   */
  updateSettlementOpenManagement = (
    params: {
      openYn: string
      yyyyMm: string
    } & BaseRequest,
  ): Promise<{ updateSettlementOpenManagement: number }> => {
    const url = '/apis/settlement-open-management/update'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 워커팝업화면 제외금액 조회
   */
  selectSettlementPayExceptinfo = (
    params: {
      yyyyMm: string
      workUserId: number
    } & BaseRequest,
  ): Promise<{ selectSettlementPayExcept: CWSelectSettlementPayExceptVo }> => {
    const url = '/api/settlement-pay-except/info'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
