import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import { BasicMainTask, BasicUserTotal, CWExpectationCost, ProUserTotal } from '../model'

export class CWMainApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 메인에 들어갈 전체 정보
   */
  basicList = (
    params: {
      userId: number
      taskStepCd?: string
      statusCd?: string
    } & BaseRequest,
  ): Promise<{
    basicMainTaskList: BasicMainTask[]
    basicUserTotal: BasicUserTotal
  }> => {
    const url = '/p/crowd/basic-main/task/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 메인에 들어갈 전체 정보
   */
  proList = (
    params: {
      userId: number
      taskStepCd?: string
      statusCd?: string
    } & BaseRequest,
  ): Promise<{
    proMainTaskList: BasicMainTask[]
    proUserTotal: ProUserTotal
  }> => {
    const url = '/api/crowd/pro-main/task/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 예상금액 조회
   */
  expectionCostList = (
    params: {} & BaseRequest,
  ): Promise<{ expectationCost: CWExpectationCost }> => {
    const url = '/p/crowd/expection-cost'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
