import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
// import { CWExtractBook, CWExtractPage } from '../model'
import {
  CWInspectionPage,
  CWInspectionInsert,
  CWInspectionVerify,
  CWInspectionVerifyInsert,
  CWInspectionInsert92,
  CWInspectionPage92,
  CWInspectionVerifyPage92,
  CWInspectionRetryInsert,
  CWInspectionVerifyRetry,
  CWInspectionRetryPage92,
  CWInspectionRetryVerifyPage92,
} from '../model'
/** clover framework NAV API **/
export class CWInspectionApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }
  /**
   * 정제 할 단락 조회 (page)
   * @param params
   * @returns
   **/
  page = (
    params: {
      assignId: number // 일감 배정 ID
    } & BaseRequest,
  ): Promise<{
    refineInfo: CWInspectionPage
  }> => {
    const url = '/apis/refine/page'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 정제 검수 할 단락 조회 (verify)
   * @param params
   * @returns
   **/
  verify = (
    params: {
      assignId: number // 일감 배정 ID
    } & BaseRequest,
  ): Promise<{
    refineInfo: CWInspectionVerify
  }> => {
    const url = '/apis/refine/verify'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 정제 insert
   * @param params
   * @returns
   **/
  insert = (
    params: CWInspectionInsert & BaseRequest,
  ): Promise<{
    result: string
    nextPageNumber: number
    isFinal: string
  }> => {
    const url = '/apis/refine/insert'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 정제 verifyInsert
   * @param params
   * @returns
   **/
  verifyInsert = (
    params: CWInspectionVerifyInsert & BaseRequest,
  ): Promise<{
    result: string
    isFinal: string
  }> => {
    const url = '/apis/refine/verify/insert'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 정제 할 단락 조회 (page 9-2)
   * @param params
   * @returns
   **/
  page92 = (
    params: {
      assignId: number // 일감 배정 ID
    } & BaseRequest,
  ): Promise<{
    illustrationInfo: CWInspectionPage92
  }> => {
    const url = '/apis/refine/illustration'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 정제 insert 9-2
   * @param params
   * @returns
   **/
  insert92 = (
    params: CWInspectionInsert92 & BaseRequest,
  ): Promise<{
    result: string
    isFinal: string
  }> => {
    const url = '/apis/refine/illustration/insert'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 정제 할 단락 조회 (page 9-2)
   * @param params
   * @returns
   **/
  verify92 = (
    params: {
      assignId: number // 일감 배정 ID
    } & BaseRequest,
  ): Promise<{
    illustrationInfo: CWInspectionVerifyPage92
  }> => {
    const url = '/apis/refine/illustration/verify'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 정제 insert 9-2
   * @param params
   * @returns
   **/
  insertVerify92 = (
    params: CWInspectionInsert92 & BaseRequest,
  ): Promise<{
    result: string
    isFinal: string
  }> => {
    const url = '/apis/refine/illustration/verify/insert'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 정제 할 단락 조회 (page)
   * @param params
   * @returns
   **/
  retryPage = (
    params: {
      assignId: number // 일감 배정 ID
    } & BaseRequest,
  ): Promise<{
    refineInfo: CWInspectionPage
  }> => {
    const url = '/apis/refine/page/retry'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 정제 insert
   * @param params
   * @returns
   **/
  retryInsert = (
    params: CWInspectionRetryInsert & BaseRequest,
  ): Promise<{
    result: string
    isFinal: string
  }> => {
    const url = '/apis/refine/retry/update'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 재작업 정제 검수 할 단락 조회 (verifyRetry)
   * @param params
   * @returns
   **/
  verifyRetry = (
    params: {
      assignId: number // 일감 배정 ID
    } & BaseRequest,
  ): Promise<{
    refineInfo: CWInspectionVerifyRetry
  }> => {
    const url = '/apis/refine/verify/retry'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 정제 할 단락 조회 (page 9-2)
   * @param params
   * @returns
   **/
  retryPage92 = (
    params: {
      assignId: number // 일감 배정 ID
    } & BaseRequest,
  ): Promise<{
    illustrationInfo: CWInspectionRetryPage92
  }> => {
    const url = '/apis/refine/illustration/retry'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 정제 retry insert 9-2
   * @param params
   * @returns
   **/
  retryInsert92 = (
    params: CWInspectionInsert92 & BaseRequest,
  ): Promise<{
    result: string
    isFinal: string
  }> => {
    const url = '/apis/refine/illustration/insert'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 정제 할 재작업 단락 조회 (retry page 9-2)
   * @param params
   * @returns
   **/
  retryVerify92 = (
    params: {
      assignId: number // 일감 배정 ID
    } & BaseRequest,
  ): Promise<{
    illustrationInfo: CWInspectionRetryVerifyPage92
  }> => {
    const url = '/apis/refine/illustration/verify/retry'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
