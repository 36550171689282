import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import { MetaWord } from '../model'

/**
 * 메타 관련 API
 */
export class MetaApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 메타 단어 정보 조회
   */
  wordInfo = (
    params: {
      wordId: number
    } & BaseRequest,
  ): Promise<{ metaWord: MetaWord }> => {
    const url = '/apis/meta/word/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 메타 단어 검색
   */
  wordSearch = (
    params: {
      name?: string
      fullName?: string
      korName?: string
      entityYn?: string
      attrYn?: string
      synm?: string
      expl?: string
      sortKey?: string
      maxCount: number
    } & BaseRequest,
  ): Promise<{ metaWordList: MetaWord[] }> => {
    const url = '/apis/meta/word/search'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 신규 메타 단어 등록
   */
  createWord = (
    params: {
      name: string
      fullName?: string
      korName: string
      synm?: string
      entityYn: 'Y' | 'N'
      attrYn: 'Y' | 'N'
      expl?: string
    } & BaseRequest,
  ): Promise<{ metaWord: MetaWord }> => {
    const url = '/apis/meta/word/create'
    console.log(params)
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 메타 단어 업데이트
   */
  updateWord = (
    params: {
      id: number
      name: string
      fullName?: string
      korName: string
      synm?: string
      entityYn: 'Y' | 'N'
      attrYn: 'Y' | 'N'
      expl?: string
    } & BaseRequest,
  ): Promise<{ metaWord: MetaWord }> => {
    const url = '/apis/meta/word/update'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 메타 단어 존재 여부 체크
   * 존재하면 true를 리턴
   */
  wordExistsByName = (
    params: {
      name: string
    } & BaseRequest,
  ): Promise<boolean> => {
    const url = '/apis/meta/word/exists'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 메타 단어 삭제
   */
  wordDelete = async (
    params: {
      wordId: number
    } & BaseRequest,
  ): Promise<void> => {
    const [data, extra] = splitParams(params)
    const url = `/apis/meta/word/delete/${data.wordId}`
    await this.helper.post(url, undefined, extra)
  }
}
