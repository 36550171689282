import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import { CWBook } from '../model'

/**
 * clover framework NAV API
 */
export class CWCommonApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }
  /**
   * 도서 정보 조회
   * @param params
   * @returns
   */
  book = (
    params: {
      assignId: number // 일감 배정 ID
    } & BaseRequest,
  ): Promise<{
    bookInfo: CWBook
  }> => {
    const url = '/apis/cmmn/book/info'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
