import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import {
  AuthorByBookCount,
  AuthorRate,
  CWBookSupplyRate,
  CWMakeReadyCnt,
  CWProgressRateVo,
  CWUnWorkedWorker,
  Class5And3Rate,
  ImplicationRate,
  Level3LabelRate,
  Level5LabelRate,
  LongShortRate,
  PagerData,
  ParagraphShortDetail,
  QaAnswerRate,
  UttanceRate,
  WorkGoalCurrentRate,
  labelBook16ImageDetail,
} from '../model'

/**
 * 통계 관리 API
 */
export class CWStatisticsManageApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 작업 미수행 워커 목록 조회 - 페이징
   */
  unWorkedWorkerList = (
    params: {
      value: number
      rowsPerPage: number
      pageNumber: number
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<CWUnWorkedWorker> }> => {
    const url = '/apis/un-worked-worker/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 도서수급율 조회
   */
  rateList = (
    params: { workNumber: string } & BaseRequest,
  ): Promise<{ rateList: CWBookSupplyRate[] }> => {
    const url = '/apis/crowdwork/rate/list'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 보정 필요 조회
   */
  makeReadyCnt = (params: BaseRequest): Promise<CWMakeReadyCnt> => {
    const url = '/apis/crowdwork/make-ready/count'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 과제별 공정별 작업현황 조회
   */
  progressRate = (params: BaseRequest): Promise<{ progressRate: CWProgressRateVo }> => {
    const url = '/apis/crowdwork/progress-rate/list'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 9-1 다형성 조회
   */
  diversity91 = (
    params: BaseRequest,
  ): Promise<{
    implicationRate: ImplicationRate
    qaAnswerRates: QaAnswerRate[]
    level5LabelRates: Level5LabelRate[]
    level3LabelRates: Level3LabelRate[]
    class5And3Rates: Class5And3Rate[]
  }> => {
    const url = '/apis/crowdwork/diversity/9-1'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 9-1 다형성 조회
   */
  diversity92 = (
    params: BaseRequest,
  ): Promise<{
    uttanceRate: UttanceRate
    authorRate: AuthorRate[]
    level5LabelRates: Level5LabelRate[]
    level3LabelRates: Level3LabelRate[]
    class5And3Rates: Class5And3Rate[]
    labelBook16Image: { bookCnt: number; labelCnt: number }
    labelBook16ImageDetails: labelBook16ImageDetail[]
  }> => {
    const url = '/apis/crowdwork/diversity/9-2'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 9-1 다형성 조회
   */
  diversity93 = (
    params: BaseRequest,
  ): Promise<{
    longShortRate: LongShortRate
    paragraphShortCount: { bookCnt: number; labelCnt: number }
    paragraphShortDetails: ParagraphShortDetail[]
    level5LabelRates: Level5LabelRate[]
    level3LabelRates: Level3LabelRate[]
    class5And3Rates: Class5And3Rate[]
    authorByBookCounts: AuthorByBookCount[]
  }> => {
    const url = '/apis/crowdwork/diversity/9-3'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 9-1 단락별 태깅수
   */
  paraTagging91 = (
    params: BaseRequest,
  ): Promise<{
    taggingLabelingCnts: { labelingCnt: string; paragraphCnt: number }[]
    taggingTagCnts: { tagCnt: string; paragraphCnt: number }[]
  }> => {
    const url = '/apis/crowdwork/para-tagging-91'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 목표달성현황
   */
  workGoalCurrentRate = (
    params: BaseRequest,
  ): Promise<{
    workGoalCurrentRates: WorkGoalCurrentRate[]
  }> => {
    const url = '/apis/crowdwork/work-goal-current/rate'
    return this.withData.post(url, ...splitParams(params))
  }
}
