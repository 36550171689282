import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import {
  CWLabelIllustration,
  CWLabelParagraph,
  CWLabelQA,
  CWLabelRetryParagraph,
  CWLabelStory,
  CWLabelStoryRetryVo,
  CWLabelTag,
  CWLabelVarifyQuestion,
  CWLabelVerifyIllustration,
  CWLabelVerifyStory,
} from '../model'

export class CWLabelingApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  qaCreate = (
    params: {
      qaRefineVerifyStepDataId: number
      cwLabelQAList: CWLabelQA[]
      statusCd: string
      assignId: number
      completePercent: number
    } & BaseRequest,
  ): Promise<{
    result: number
  }> => {
    const url = '/p/crowd/labeling_qa/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  qaInfo = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    labelParagraphVo: CWLabelParagraph
    cwLabelTagList: CWLabelTag[]
  }> => {
    const url = '/p/crowd/labeling_qa/info'
    return this.withData.post(url, ...splitParams(params))
  }
  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  storyInfo = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    labelStoryVo: CWLabelStory
    cwLabelTagList: CWLabelTag[]
  }> => {
    const url = '/p/crowd/labeling_story/info'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  storyCreate = (
    params: {
      storyRefineVerStepDataId: number
      story?: string
      mainSentence?: string
      statusCd: string
      assignId: number
      completePercent: number
      retryStatus?: string
      paragraphNumber?: number
    } & BaseRequest,
  ): Promise<{
    saveResult: number
  }> => {
    const url = '/p/crowd/labeling_story/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  illuInfo = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    labelIllustrationVo: CWLabelIllustration
    cwLabelTagList: CWLabelTag[]
  }> => {
    const url = '/p/crowd/labeling-illu/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  illuCreate = (
    params: {
      illustrationRefineVerifyStepDataId: number
      illustrationLabeling?: string
      utteranceTypeCd?: string
      statusCd: string
      assignId: number
      completePercent: number
    } & BaseRequest,
  ): Promise<{
    saveResult: number
  }> => {
    const url = '/p/crowd/labeling-illu/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  // ------------------------------------------------재작업 관련 api----------------------------------------------
  /**
   * 라벨링 작업자에 재작업으로 할당된 질의응답 작업 정보 등록
   */
  qaRetryCreate = (
    params: {
      qaLabelingStepDataId: number
      statusCd: string
      cwLabelQAList: CWLabelQA & { qaLabelingStepQuestionId?: number }[]
      assignId?: number
      primitiveBookId?: number
      paragraphNumber?: number
      completePercent?: number
    } & BaseRequest,
  ): Promise<{
    result: number
    lastpageYn: string
  }> => {
    const url = '/api/crowd/labeling_qa/retry/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  qaRetryInfo = (
    params: {
      assignId: number
      primitiveBookId?: number
    } & BaseRequest,
  ): Promise<{
    labelRetryParagraphVo: CWLabelRetryParagraph
    cwLabelTagList: CWLabelTag[]
    cwLabelVarifyQuestionVoList: CWLabelVarifyQuestion[]
  }> => {
    const url = '/api/crowd/labeling_qa/retry/info'
    return this.withData.post(url, ...splitParams(params))
  }
  /**
   * 라벨링 작업자에 할당된 일러스트 작업 정보 등록
   */
  illuRetryCreate = (
    params: {
      illLabelingStepDataId: number
      statusCd: string
      utteranceTypeCd?: string
      illustrationLabeling?: string
      assignId?: number
      primitiveBookId?: number
      paragraphNumber?: number
      completePercent?: number
    } & BaseRequest,
  ): Promise<{
    result: number
    lastpageYn: string
  }> => {
    const url = '/api/crowd/labeling_illu/retry/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  illuRetryInfo = (
    params: {
      assignId: number
      primitiveBookId?: number
    } & BaseRequest,
  ): Promise<{
    labelRetryIlluParagraphVo: CWLabelVerifyIllustration & {
      primitiveBookId?: number
      statusMessage?: string
    }
    cwLabelTagList: CWLabelTag[]
  }> => {
    const url = '/api/crowd/labeling_illu/retry/info'
    return this.withData.post(url, ...splitParams(params))
  }
  /**
   * 라벨링 작업자에 할당된 스토리 작업 정보 등록
   */
  storyRetryCreate = (
    params: {
      storyLabelingStepDataId: number
      statusCd: string
      mainSentence?: string
      story?: string
      assignId?: number
      primitiveBookId?: number
      paragraphNumber?: number
    } & BaseRequest,
  ): Promise<{
    result: number
    lastpageYn: string
  }> => {
    const url = '/api/crowd/labeling_story/retry/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 스토리 작업 정보 조회
   */
  storyRetryInfo = (
    params: {
      assignId: number
      primitiveBookId?: number
    } & BaseRequest,
  ): Promise<{
    labelRetryStoryParagraphVo: CWLabelStoryRetryVo
    cwLabelTagList: CWLabelTag[]
  }> => {
    const url = '/api/crowd/labeling_story/retry/info'
    return this.withData.post(url, ...splitParams(params))
  }
}
