import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import { CWProfessorDataSetVo, CWProfessorOpinionPaperVo, PagerData } from '../model'

export class CWProfessorApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 메인에 들어갈 전체 정보
   */
  professorDataSetList = (
    params: {
      workNumber: string
      bookTitle?: string
      rowsPerPage: number
      pageNumber: number
    } & BaseRequest,
  ): Promise<{
    professorDataSetList: PagerData<CWProfessorDataSetVo>
  }> => {
    const url = '/apis/professor-data-set/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 의견서 작성현황 목록 조회
   */
  opinionPaperList = (
    params: {
      inspectionNo: number
      regUserid?: number
    } & BaseRequest,
  ): Promise<{ opinionPaperList: CWProfessorOpinionPaperVo[] }> => {
    const url = '/apis/professor-opinion-paper/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 의견서 작성
   */
  opinionPaperInsert = (
    params: {
      inspectionNo: number
      organizationNm: string
      positionNm: string
      finalDegreeNm: string
      inspectionResult: string
      saveCd: string
    } & BaseRequest,
  ): Promise<{
    opinionPaperInsert: number
  }> => {
    const url = '/apis/professor-opinion-paper/insert'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
