import { TableBody, TableBodyProps } from '@mui/material'
import clsx from 'clsx'
import { SxProps, Theme } from '@mui/material'
import { flatSx } from './../lib/sx-props'

const rootSx: SxProps<Theme> = {
  '&.CLDocTableBody-loading': {
    opacity: 0.5,
  },
  '&.CLDocTableBody-stripe': {
    '& .MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: (theme) => theme.palette.action.hover,
    },
  },
}

type Props = {
  loading?: boolean
  stripe?: boolean
  disableCenterAlign?: boolean
  textAlign?: 'left' | 'center' | 'right'
  yPadding?: 'small' | 'medium' | 'large'
  // sx?: SxProps
} & TableBodyProps

export function CLDocTableBody(props: Props) {
  const {
    textAlign = 'left',
    yPadding = 'medium',
    loading,
    stripe,
    disableCenterAlign = false,
    sx,
    className,
    children,
    ...restProps
  } = props

  const py = yPadding === 'small' ? '2px' : yPadding === 'medium' ? '8px' : '12px'
  const tA = textAlign === 'center' ? 'center' : textAlign === 'right' ? 'rigth' : textAlign
  return (
    <TableBody
      sx={flatSx(
        rootSx,
        {
          '& > .MuiTableRow-root > .MuiTableCell-root': {
            textAlign: tA,
            py: py,
            fontSize: '0.75rem',
          },
        },
        sx,
      )}
      className={clsx('CLDocTableBody-root', className, {
        'CLDocTableBody-stripe': !!stripe,
        'CLDocTableBody-loading': !!loading,
      })}
      {...restProps}
    >
      {children}
    </TableBody>
  )
}
