import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import {
  CWLabelQA,
  CWLabelTag,
  CWLabelVarifyParagraph,
  CWLabelVarifyQuestion,
  CWLabelVerifyIllustration,
  CWLabelVerifyStory,
} from '../model'

export class CWLabelingCheckApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  qaInfo = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    labelVarifyParagraphVo: CWLabelVarifyParagraph
    cwLabelVarifyQuestionVoList: CWLabelVarifyQuestion[]
    cwLabelTagList: CWLabelTag[]
  }> => {
    const url = '/p/crowd/labeling_qa/varify/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 등록
   */
  qaCreate = (
    params: {
      qaLabelingStepDataId: number
      cwLabelQAList: CWLabelQA[]
      statusCd: string
      assignId: number
      completePercent: number
      statusMessage?: string
    } & BaseRequest,
  ): Promise<{
    result: number
  }> => {
    const url = '/p/crowd/labeling_qa/verify/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  storyInfo = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    labelStoryVerifyVo: CWLabelVerifyStory
    cwLabelTagList: CWLabelTag[]
  }> => {
    const url = '/p/crowd/labeling_story/verify/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  storyCreate = (
    params: {
      storyLabelingStepDataId: number
      story?: string
      mainSentence?: string
      statusCd: string
      chgrTf: boolean
      assignId: number
      completePercent: number
      statusMessage?: string
    } & BaseRequest,
  ): Promise<{
    saveResult: number
  }> => {
    const url = '/p/crowd/labeling_story/verify/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  illuInfo = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    labelIllustrationVerifyVo: CWLabelVerifyIllustration
    cwLabelTagList: CWLabelTag[]
  }> => {
    const url = '/p/crowd/labeling_illu/verify/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  illuCreate = (
    params: {
      illLabelingStepDataId: number
      illustrationLabeling?: string
      utteranceTypeCd?: string
      statusCd: string
      chgrTf: boolean
      assignId: number
      completePercent: number
      assignStatusCd?: string
      statusMessage?: string
    } & BaseRequest,
  ): Promise<{
    saveResult: number
  }> => {
    const url = '/p/crowd/labeling-illu/verify/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  //---------------------------------재검수

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 조회
   */
  qaRequestRetryInfo = (
    params: {
      assignId: number
      primitiveBookId?: number
    } & BaseRequest,
  ): Promise<{
    labelVarifyParagraphVo: CWLabelVarifyParagraph & {
      primitiveBookId: number
      qaLabelingVerStepDataId: number
    }
    cwLabelVarifyQuestionVoList: CWLabelVarifyQuestion[]
    cwLabelTagList: CWLabelTag[]
  }> => {
    const url = '/api/crowd/labeling_qa/retry_verify/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 질의응답 작업 정보 등록
   */
  qaRequestRetryCreate = (
    params: {
      qaLabelingStepDataId?: number
      qaLabelingVerStepDataId?: number
      cwLabelQAList: CWLabelQA[]
      statusCd: string
      assignId: number
      statusMessage?: string
      primitiveBookId?: number
      paragraphNumber?: number
    } & BaseRequest,
  ): Promise<{
    result: number
    lastpageYn: string
  }> => {
    const url = '/api/crowd/labeling_qa/retry-verify/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 일러스트 작업 정보 조회
   */
  illuRequestRetryInfo = (
    params: {
      assignId: number
      primitiveBookId?: number
    } & BaseRequest,
  ): Promise<{
    labelVarifyParagraphVo: CWLabelVarifyParagraph & {
      primitiveBookId: number
      qaLabelingVerStepDataId: number
    }
    labelIllustrationVerifyVo: CWLabelVerifyIllustration
    cwLabelTagList: CWLabelTag[]
  }> => {
    const url = '/p/crowd/labeling_illu/retry-verify/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 일러스트 재검수 등록
   */
  illuRequestRetryCreate = (
    params: {
      illLabelingStepDataId?: number
      illustrationLabeling?: string
      utteranceTypeCd?: string
      statusCd: string
      assignId?: number
      primitiveBookId?: number
      statusMessage?: string
      paragraphNumber?: number
    } & BaseRequest,
  ): Promise<{
    result: number
    lastpageYn: string
  }> => {
    const url = '/api/crowd/labeling_illu/retry-verify/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 일러스트 작업 정보 조회
   */
  storyRequestRetryInfo = (
    params: {
      assignId: number
      primitiveBookId?: number
    } & BaseRequest,
  ): Promise<{
    labelStoryVerifyVo: CWLabelVerifyStory
    cwLabelTagList: CWLabelTag[]
  }> => {
    const url = '/p/crowd/labeling_illu/retry-varify-story/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 라벨링 작업자에 할당된 일러스트 작업 정보 조회
   */
  storyRequestRetryCreate = (
    params: {
      storyLabelingStepDataId: number
      story?: string
      mainSentence?: string
      statusCd: string
      chgrTf: boolean
      assignId: number
      statusMessage?: string
      primitiveBookId?: number
      paragraphNumber?: number
    } & BaseRequest,
  ): Promise<{
    result: number
    lastpageYn: string
  }> => {
    const url = '/api/crowd/labeling_story/retry-verify/create'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
