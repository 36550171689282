import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import { CLPriv, CLRole, CLRolePageDetail } from '../model'

/**
 * clover framework ROLE API
 */
export class CLPrivApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * priv 목록
   */
  privPList = (
    params: BaseRequest,
  ): Promise<{
    privList: CLPriv[]
  }> => {
    const url = '/apis/cloverframework/privilege/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * priv 신규
   */
  createPriv = (
    params: {
      privId: string
      privNm: string
      dtlExpl?: string
    } & BaseRequest,
  ): Promise<{
    role: CLPriv
  }> => {
    const url = '/apis/cloverframework/privilege/add'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * priv 업뎃
   */
  updatePriv = (
    params: {
      privId: string
      privNm: string
      dtlExpl?: string
    } & BaseRequest,
  ): Promise<{
    role: CLRole
  }> => {
    const url = '/apis/cloverframework/privilege/update'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * priv 삭제
   */
  deletePriv = async (
    params: {
      privId: string
    } & BaseRequest,
  ) => {
    const url = '/apis/cloverframework/privilege/delete'
    await this.helper.post(url, ...splitParams(params))
  }
}
