import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import {
  CWAdminIntegListVo,
  CWExtractView,
  CWIntegrationIllustUpdateVo,
  CWLabelingView,
  CWUpdateViewInfo,
  PagerData,
} from '../model'

/**
 * clover framework NAV API
 */
export class CWAdminInteUpdateApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 태깅값 수정
   */
  updateTagging = (
    params: {
      workNumber: string
      id: number
      beforeTag: string
      tagTypeCd: string
      tag: string
      assignId: number
    } & BaseRequest,
  ): Promise<{
    content: string
  }> => {
    const url = '/apis/admin/integration-up/tagging'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 통합검수
   * @param params
   * @returns
   */
  infoInteger = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    integList: CWUpdateViewInfo[]
  }> => {
    const url = '/apis/admin/integration-up/info'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 통합검수9-2 작업 내용 조회
   * @param params
   * @returns
   */
  integrationIllustList = (
    params: {
      assignId: number
    } & BaseRequest,
  ): Promise<{
    illustIntegList: CWIntegrationIllustUpdateVo[]
  }> => {
    const url = '/apis/admin/integration-up/illu-info'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 통합검수
   * @param params
   * @returns
   */
  updatePara = (
    params: {
      workNumber: string
      stepDataId: number
      paragraph: string
      asIsParagraph: string
      assignId: number
    } & BaseRequest,
  ): Promise<{
    result: number
  }> => {
    const url = '/apis/admin/integration-up/paragraph'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 통합검수
   * @param params
   * @returns
   */
  qaLavelingUpdate = (
    params: {
      stepQuestionId: number
      question: string
      implicationCd: string
      questionFormCd: string
      answer1: string
      answer2: string
      answer3: string
      answer4: string
      answer5: string

      assignId: number
      asIsQuestion: string
      asIsImplicationCd: string
      asIsQuestionFormCd: string
      asIsAnswer1: string
      asIsAnswer2: string
      asIsAnswer3: string
      asIsAnswer4: string
      asIsAnswer5: string
    } & BaseRequest,
  ): Promise<{
    result: number
  }> => {
    const url = '/apis/admin/integration-up/qa-labeling'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 통합검수
   * @param params
   * @returns
   */
  mainSentenceStoryUpdate = (
    params: {
      stepDataId: number
      mainSentence?: string
      story?: string
      assignId: number
      asIsMainSentence?: string
      asIsStory?: string
    } & BaseRequest,
  ): Promise<{
    result: number
  }> => {
    const url = '/apis/admin/integration-up/main-story'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 통합검수
   * @param params
   * @returns
   */
  illuUtterUpdate = (
    params: {
      stepDataId: number
      illustrationLabeling: string
      utteranceTypeCd: string

      assignId: number
      asIsIllustrationLabeling: string
      asIsUtteranceTypeCd: string
    } & BaseRequest,
  ): Promise<{
    result: number
  }> => {
    const url = '/apis/admin/integration-up/illu-uter-labelling'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 9-1,9-2,9-3 데이터셋 수정 조회 목록 - 페이징
   */
  adminIntegList = (
    params: {
      workNumber: string
      bookTitle?: string
      rowsPerPage: number
      pageNumber: number
    } & BaseRequest,
  ): Promise<{ adminIntegList: PagerData<CWAdminIntegListVo> }> => {
    const url = '/apis/admin-integ/list'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
