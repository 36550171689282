import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import { CWBookSupplyRate, CWUnWorkedWorker, CWWorkerFacts, PagerData } from '../model'

/**
 * 통계 관리 API
 */
export class CWWorkerFactApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 계약중인 워커 작업 상태 현황 다건 조회
   */
  wrkStatCdCurrentList = (
    params: BaseRequest,
  ): Promise<{ wrkStatCdCurrentList: CWWorkerFacts[] }> => {
    const url = '/apis/worker-wrk-stat-cd-current/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 계약 현황 다건 조회
   */
  contStatCurrentList = (
    params: BaseRequest,
  ): Promise<{ contStatCurrentList: CWWorkerFacts[] }> => {
    const url = '/apis/worker-const-stat-cd-current/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 워커 연령별 현황
   */
  ageList = (params: BaseRequest): Promise<{ ageList: CWWorkerFacts[] }> => {
    const url = '/apis/worker-age-current/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 성별 현황
   */
  genderList = (params: BaseRequest): Promise<{ countList: CWWorkerFacts[] }> => {
    const url = '/apis/worker-gender-count/list'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
