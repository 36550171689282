import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import { CWExtractPage } from '../model'
import { isLoginError } from '..'
import axios from 'axios'

/**
 * clover framework NAV API
 */
export class CWExtractApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 추출 페이지 정보 조회
   * @param params
   * @returns
   */
  page = (
    params: {
      assignId: number
      statusCd?: string
    } & BaseRequest,
  ): Promise<{
    pageInfo: CWExtractPage
  }> => {
    const url = '/apis/extract/page'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 추출 페이지 삽화 추가
   * @param params
   * @returns
   */
  imageInsert = (
    pageId: string,
    imageList: File[] & BaseRequest,
  ): Promise<{
    imageList: {
      pageImagePath: string
      pageImageUrl: string
      pageImageName: string
    }[]
  }> => {
    const form = new FormData()
    imageList.map((file) => form.append('file', file))
    const url = `/p/extract/page/image/upload/${pageId}`
    return this.withData.postMultipart(url, form)
  }
  /**
   * 추출 페이지 작업결과 저장
   * @param params
   * @returns
   */
  insert = (
    params: {
      workNumber: string //과제 번호
      primitiveBookId: number //원시 책 id
      assignId: number //일감 배정 ID
      pageId: string //페이지 ID
      pageNumber: number //페이지 순번
      pageText: string //페이지 추출 내용 텍스트
      pageImageStatusCd: 'USED' | 'UNUSED' | 'MAKEREADY' | '' //페이지 삽화 상태 - 사용 | 사용불가 | 보정필요
      statusCd: String //추출상태 - 사용 | 작업불가
      extractParagraphs: {
        //단락 리스트
        paragraph: string //단락 내용
        sortNumber: number //단락 순서
        wordCount: number //어절수
        sentenceCount: number //문장수
      }[]
      extractDataId?: number // 재작업시에만 전송
      imageList?: {
        imagePath: string
        imageUrl: string
        imageName: string
        imageNumber: number
      }[]
    } & BaseRequest,
  ): Promise<{
    result: boolean
    percent: number
  }> => {
    const url = '/apis/extract/page/insert'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 추출 페이지 정보 조회
   * @param params
   * @returns
   */
  verify = (
    params: {
      assignId: number
      statusCd?: string
    } & BaseRequest,
  ): Promise<{
    pageInfo: CWExtractPage
  }> => {
    const url = '/apis/extract/verify'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 추출 페이지 작업결과 저장
   * @param params
   * @returns
   */
  verifyInsert = (
    params: {
      workNumber: string //과제 번호
      primitiveBookId: number //원시 책 id
      assignId: number //일감 배정 ID
      pageId: string //페이지 ID
      pageNumber: number //페이지 순번
      pageText: string //페이지 추출 내용 텍스트
      pageImageStatusCd: 'USED' | 'UNUSED' | 'MAKEREADY' | '' //페이지 삽화 상태 - 사용 | 사용불가 | 보정필요
      statusCd: String //추출상태 - 사용 | 작업불가
      extractParagraphs: {
        //단락 리스트
        paragraph: string //단락 내용
        sortNumber: number //단락 순서
        wordCount: number //어절수
        sentenceCount: number //문장수
      }[]
      statusMessage?: string //재작업 사유
      pageImageStatusMessage?: string // 이미지 보정 사유
    } & BaseRequest,
  ): Promise<{
    result: boolean
    percent: number
  }> => {
    const url = '/apis/extract/verify/insert'

    return this.withData.postJson(url, ...splitParams(params))
  }
}
