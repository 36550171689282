import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import { CLCode, PagerData } from '../model'

/**
 * clover framework 코드 API
 */
export class CLCodeApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 주어진 검색 조건으로 코드 목록 조회 - 페이지
   */
  search = (
    params: {
      codeType?: string
      code?: string
      codeNm?: string

      /**
       * keyword는 code 또는 codeNm으로 검색한다(OR 조건)
       */
      keyword?: string
      rowsPerPage: number
      pageNumber: number
    } & BaseRequest,
  ): Promise<{
    pagerData: PagerData<CLCode>
  }> => {
    const url = '/apis/cloverframework/code/search'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 코드 조회
   */
  info = (
    params: {
      codeType: string // pk1
      code: string // pk2
    } & BaseRequest,
  ): Promise<{
    code: CLCode
  }> => {
    const url = '/apis/cloverframework/code/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 코드 등록 - 단건 등록
   * 등록하기 전에 codeType이 먼저 등록되어야 한다
   */
  create = (
    params: {
      codeType: string // pk1
      code: string // pk2
      codeNm: string
      dtlExpl?: string
    } & BaseRequest,
  ): Promise<{
    code: CLCode
  }> => {
    const url = '/apis/cloverframework/code/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 코드 업데이트 - 단건 업데이트
   */
  update = (
    params: {
      codeType: string // pk1
      code: string // pk2
      codeNm: string
      dtlExpl?: string
    } & BaseRequest,
  ): Promise<{
    code: CLCode
  }> => {
    const url = '/apis/cloverframework/code/update'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 코드 삭제
   */
  delete = async (
    params: {
      codeType: string // pk1
      code: string // pk2
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/apis/cloverframework/code/delete'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * 코드 등록 - 다건 등록
   * 등록하기 전에 codeType이 먼저 등록되어야 한다
   */
  saveAll = async (
    params: {
      codeType: string // pk1
      codes: Array<{
        code: string
        codeNm: string
        dtlExpl?: string
      }>
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/apis/cloverframework/code/save-all'
    await this.helper.postJson(url, ...splitParams(params))
  }
}
