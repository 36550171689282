import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import { CLNav, CLNavItem, CLPage, CLPageSection } from '../model'

/**
 * clover framework NAV API
 */
export class CLNavApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  createNav = (
    params: {
      navNm: string
      expl?: string
    } & BaseRequest,
  ): Promise<{
    nav: CLNav
  }> => {
    const url = '/apis/cloverframework/nav/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  updateNav = (
    params: {
      navId: number
      navNm: string
      expl?: string
    } & BaseRequest,
  ): Promise<{
    nav: CLNav
  }> => {
    const url = '/apis/cloverframework/nav/update'
    return this.withData.postJson(url, ...splitParams(params))
  }

  navList = (
    params: BaseRequest,
  ): Promise<{
    navList: CLNav[]
  }> => {
    const url = '/apis/cloverframework/nav/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  deleteNav = async (
    params: {
      navId: number
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/apis/cloverframework/nav/delete'
    await this.helper.post(url, ...splitParams(params))
  }

  items = (
    params: {
      /**
       * NAV ID
       */
      navId: number
      /**
       * 숨겨진 항목 포함해서 조회 할지 여부
       */
      withHidden: boolean
    } & BaseRequest,
  ): Promise<{
    nav: CLNav
    navItemList: CLNavItem[]
  }> => {
    const url = '/apis/cloverframework/nav/items'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 아이템 정렬
   */
  sortItems = (
    params: {
      navId: number
      items: Array<{
        pageId: number
        sectionId?: number
      }>
    } & BaseRequest,
  ): Promise<{
    nav: CLNav
    navItemList: CLNavItem[]
  }> => {
    const url = '/apis/cloverframework/nav/sort-items'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 페이지 신규 등록
   */
  createPage = (
    params: {
      pageNm: string
      pageKey?: string
      url: string
      icon?: string
      dtlExpl?: string
    } & BaseRequest,
  ): Promise<{
    page: CLPage
  }> => {
    const url = '/apis/cloverframework/nav/create-page'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 페이지 업데이트
   */
  updatePage = (
    params: {
      pageId: number
      pageNm: string
      pageKey?: string
      url: string
      icon?: string
      dtlExpl?: string
    } & BaseRequest,
  ): Promise<{
    page: CLPage
  }> => {
    const url = '/apis/cloverframework/nav/update-page'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 페이지 삭제
   */
  deletePage = async (
    params: {
      pageId: number
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/apis/cloverframework/nav/delete-page'
    await this.helper.post(url, ...splitParams(params))
  }
  /**
   * 페이지 전체 목록
   */
  pages = (
    params: BaseRequest,
  ): Promise<{
    pageList: CLPage[]
  }> => {
    const url = '/apis/cloverframework/nav/page-list'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 섹션 신규 등록
   */
  createSection = (
    params: {
      sectionNm: string
      icon?: string
    } & BaseRequest,
  ): Promise<{
    section: CLPageSection
  }> => {
    const url = '/apis/cloverframework/nav/create-section'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 섹션 업데이트
   */
  updateSection = (
    params: {
      sectionId: number
      sectionNm: string
      icon?: string
    } & BaseRequest,
  ): Promise<{
    section: CLPageSection
  }> => {
    const url = '/apis/cloverframework/nav/update-section'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 섹션 삭제
   */
  deleteSection = async (
    params: {
      navId: number
      sectionId: number
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/apis/cloverframework/nav/delete-section'
    await this.helper.post(url, ...splitParams(params))
  }
  /**
   * 섹션 단건조회
   */
  sectionInfo = (
    params: {
      sectionId: number
    } & BaseRequest,
  ): Promise<{
    section: CLPageSection
  }> => {
    const url = '/apis/cloverframework/nav/section-info'
    return this.withData.post(url, ...splitParams(params))
  }
}
