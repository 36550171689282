import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import { CLPageApi } from '../model'

/**
 * clover framework NAV API
 */
export class CLApiPageApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  pageApiList = (
    params: {
      pageId: number
    } & BaseRequest,
  ): Promise<{
    pageApiList: CLPageApi[]
  }> => {
    const url = '/apis/cloverframework/pageApi/list'
    return this.withData.post(url, ...splitParams(params))
  }
  /**
   * page API 정보 업데이트
   */
  pageApiUpdate = (
    params: {
      pageId: number
      pageApiList: CLPageApi[]
    } & BaseRequest,
  ): Promise<{
    uptCnt: number
  }> => {
    const url = '/apis/cloverframework/pageApi/update'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
