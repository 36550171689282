import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const apiBaseURL = publicRuntimeConfig.API_BASE_URL ?? ''
const apiParserURL = publicRuntimeConfig.API_PARSER_URL ?? ''
const apiFormatterUrl = publicRuntimeConfig.API_FORMATTER_URL ?? ''
const _3sURL = publicRuntimeConfig.S3_URL ?? ''

const cfg = {
  httpHeaderKeys: {
    token: publicRuntimeConfig.HTTP_TOKEN_HEADER,
    tokenClear: publicRuntimeConfig.HTTP_TOKEN_CLEAR_HEADER,
  },
  apiBaseURL,
  _3sURL,
  routerBaseURL: publicRuntimeConfig.ROUTER_BASE_URL,
  baseURL: publicRuntimeConfig.BASE_URL,
  debug: !!publicRuntimeConfig.DEBUG,
  apiParserURL,
  apiFormatterUrl,
}

// log.debug('config=', cfg)
export default cfg
