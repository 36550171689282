import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@/js-api-common'
import { CLUserStateKey } from '../enum-types'
import { CLUser, CWProfessor, CWWorkerAll, PagerData, UploadUserType } from '../model'

/**
 * 사용자 관리 API
 */
export class UserManageApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 관리자 목록 조회 - 페이징
   */
  list = (
    params: {
      lgonId?: string
      userName?: string
      // 둘중에 하나로 검색
      keyword?: string
      rowsPerPage: number
      pageNumber: number
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<CLUser> }> => {
    const url = '/apis/user-manage/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 로그인관리 -> 처음 로그인 변경일
   */
  loginList = (
    params: {
      rowsPerPage: number
      pageNumber: number
      fromFstLgonDt: string
      toFstLgonDt: string
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<CLUser> }> => {
    const url = '/apis/user/worker/login/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 관리자 정보 조회
   */
  info = (
    params: {
      userId: number
    } & BaseRequest,
  ): Promise<{
    user: CLUser
  }> => {
    const url = '/apis/user-manage/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 관리자 신규등록
   */
  create = (
    params: {
      lgonId: string
      userName: string
      userState: CLUserStateKey
      regrId?: string
      bryyMndy?: string
      userTno?: string
      userExno?: string
      userGd?: number
      ctiUserNtno?: string
      memo?: string
      teamId?: number
      job?: string
    } & BaseRequest,
  ): Promise<{
    lgonId: string
  }> => {
    const url = '/apis/user-manage/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 관리자 업데이트
   */
  update = (
    params: {
      userId?: number
      userName: string
      userState: CLUserStateKey
      userStateCd?: string
      pswdInitYn?: string
      bryyMndy?: string
      userTno?: string
      userExno?: string
      userGd?: number
      ctiUserNtno?: string
      prtPosbYn?: string
      dwnlPosbYn?: string
      atntYn?: string
      memo?: string
      teamId?: string
      job?: string
    } & BaseRequest,
  ): Promise<{
    lgonId: string
  }> => {
    const url = '/apis/user-manage/update'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 관리자용 사용자 비밀번호 변경
   * 사용자 정보 페이지에서 호출함
   * 해당 사용자 초기 비밀번호로 변경
   */
  updatePassword = (
    params: {
      userId: number
      pswd: string
    } & BaseRequest,
  ): Promise<{
    user: CLUser
  }> => {
    const url = '/apis/user-manage/initpw'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 워커 목록 조회 - 페이징
   */
  workerList = (
    params: {
      email?: string
      userNm?: string
      userTno?: string
      userGd?: number
      contStatCd?: string
      wrkStatCd?: string
      keyword?: string
      job?: string
      rowsPerPage: number
      pageNumber: number
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<CLUser> }> => {
    const url = '/apis/worker-user-manage/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 워커자 신규등록
   */
  workerCreate = (
    params: {
      email: string
      userNm: string
      bryyMndy?: string
      userGd: number
      userTno?: string
      wrkStatCd?: string
      contStatCd?: string
      contSrtDt?: string
      contEndDt?: string
      userStateCd?: string
      gender?: string
      job?: string
      bankAccountCd?: string
      accountNumber?: string
    } & BaseRequest,
  ): Promise<{
    userId: number
  }> => {
    const url = '/apis/worker-user-manage/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 워커자 업데이트
   */
  workerUpdate = (
    params: {
      userId?: number
      userNm: string
      userGd: number
      bryyMndy?: string
      userTno?: string
      wrkStatCd?: string
      contStatCd?: string
      contSrtDt?: string
      contEndDt?: string
      bankAccountCd?: string
      accountNumber?: string
    } & BaseRequest,
  ): Promise<{
    userId: number
  }> => {
    const url = '/apis/worker-user-manage/update'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 워커자 정보 조회
   */
  workerInfo = (
    params: {
      userId: number
    } & BaseRequest,
  ): Promise<{
    workerUser: CLUser
  }> => {
    const url = '/apis/worker-user-manage/workerInfo'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 워커 관리자 목록 조회 페이징
   */
  adminList = (
    params: {
      lgonId?: string
      userName?: string
      userState?: string
      // 둘중에 하나로 검색
      rowsPerPage: number
      pageNumber: number
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<CLUser> }> => {
    const url = '/apis/admin-user-manage/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 워커자 작업 현황 목록 조회
   */
  workerCurrentList = (
    params: {
      userId?: number
      statusCd?: string
      taskGubun?: string
    } & BaseRequest,
  ): Promise<{ workerUser: CLUser[] }> => {
    const url = '/apis/worker-user-current/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 워커자 작업 토탈 현황 목록
   */
  workerTotalCurrentList = (
    params: {
      userId?: number
    } & BaseRequest,
  ): Promise<{ workerUser: CLUser }> => {
    const url = '/apis/worker-total-current/list'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 워커자 작업 상태 변경
   */
  workerWrkStatCdChng = (
    params: {
      userId?: number
      wrkStatCd?: string
      userChngClsfCd?: string
      contStatCd?: string
      userState?: string
      userStateCd?: string
      chngRsn?: string
    } & BaseRequest,
  ): Promise<{ response: number }> => {
    const url = '/apis/worker-stat-chng/update'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 워커자 작업 상태 변경 이력
   */
  workerWrkStatChngHst = (
    params: {
      userId?: number
    } & BaseRequest,
  ): Promise<{ workerUser: CLUser[] }> => {
    const url = '/apis/worker-stat-chng/list'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 일괄 업로드 중복확인
   */
  signUpVali = (
    params: {
      userSignUpValiList: UploadUserType[]
    } & BaseRequest,
  ): Promise<{
    userSignUpValiTfList: UploadUserType[]
  }> => {
    const url = '/apis/user-manage/sign-up/vali'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 워커 다건 등록
   */
  createAll = (
    Params: {
      workerUsers: CWWorkerAll[]
    } & BaseRequest,
  ): Promise<{ workerUser: CWWorkerAll[] }> => {
    const url = '/apis/worker-user-all/create'
    return this.withData.postJson(url, ...splitParams(Params))
  }

  /**
   * 워커자 계약 해지
   */
  cancelWorker = (
    params: {
      userId: number
      userState: string
      userStateCd: string
      chngRsn?: string
      wrkStatCd?: string
      userChngClsfCd?: string
      contStatCd?: string
    } & BaseRequest,
  ): Promise<{ userId: number }> => {
    const url = '/apis/worker-user/cancel-contract'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 품질관리자 목록 조회
   */
  qualityAdminList = (
    params: {
      lgonId?: string
      userName?: string
      userState?: string
    } & BaseRequest,
  ): Promise<{ qualityAdminList: CLUser[] }> => {
    const url = '/apis/quality-admin/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 자문교수 목록 조회
   */
  professorList = (
    params: {
      lgonId?: string
      userNm?: string
    } & BaseRequest,
  ): Promise<{ professorList: CWProfessor[] }> => {
    const url = '/apis/professor/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 자문교수용 단건 조회
   */
  professorInfo = (
    params: { userId: number } & BaseRequest,
  ): Promise<{ professorInfo: CWProfessor }> => {
    const url = '/apis/professor/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 자문교수 등록
   */
  professorInsert = (
    params: {
      lgonId: string
      userNm: string
      userTno: string
      memo?: string
    } & BaseRequest,
  ): Promise<{ professorInsert: number }> => {
    const url = '/apis/professor/insert'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 자문교수 수정
   */
  professorUpdate = (
    params: {
      userNm: string
      userTno: string
      memo?: string
      userId?: number
    } & BaseRequest,
  ): Promise<{ professorUpdate: number }> => {
    const url = '/apis/professor/update'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
